<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>企业管理 </el-breadcrumb-item>
			<el-breadcrumb-item>企业信息更新</el-breadcrumb-item>
			<el-breadcrumb-item>更新账户信息</el-breadcrumb-item>
		</el-breadcrumb>
		<!--账户信息-->
		<el-card>
			<h3>账户信息</h3>
      <WarmPrompt></WarmPrompt>
			<el-table :data="info" border class="bankAccountList mt20">
				<el-table-column prop="bankName" label="账户名称" min-width="100px"></el-table-column>
				<el-table-column prop="bankNumber" label="账号" min-width="100px"></el-table-column>
				<el-table-column prop="openBank" label="开户行" min-width="100px"></el-table-column>
				<el-table-column prop="provinceId" label="开户省" min-width="100px"></el-table-column>
				<el-table-column prop="cityId" label="开户市" min-width="100px"></el-table-column>
				<el-table-column prop="clearingNumber" label="联行号" min-width="120px"></el-table-column>
				<el-table-column prop="bankBranch" label="所属支行名称" min-width="180px"></el-table-column>
				<el-table-column prop="cfcaBankCodeName" label="开户行（票交所）" min-width="150px">
					<template v-slot="scope">
						<el-autocomplete v-model="scope.row.cfcaBankCodeName"
														 :fetch-suggestions="querySearchAsync"
														 value-key="bankName"
														 placeholder="请输入内容"
														 @select="handleSelect(scope.row)"></el-autocomplete>
					</template>
				</el-table-column>
				<el-table-column prop="isCloseAccount" label="是否结算账户" min-width="140px">
					<template v-slot="scope">
						<el-select v-model="scope.row.isCloseAccount"
						           placeholder="请选择"
						           :disabled="scope.row.isPush === 1 ? true : false">
							<el-option label="是" :value="1"></el-option>
							<el-option label="否" :value="0"></el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column prop="accountType" label="账户类别" min-width="140px">
					<template v-slot="scope">
						<el-select v-model.number="scope.row.accountType"
						           placeholder="请选择"
						           :disabled="scope.row.isPush === 1 ? true : false">
							<el-option
											v-for="item in AccountType"
											:key="item.id"
											:label="item.name"
											:value="item.id">
							</el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column prop="accountStartDay" label="账户生效日" min-width="180px">
					<template v-slot="scope">
						<el-date-picker v-model="scope.row.accountStartDay"
														type="date"
										        format="yyyy-MM-dd"
														value-format="yyyy-MM-dd"
														:disabled="scope.row.isPush === 1 ? true : false"
														placeholder="选择日期">
						</el-date-picker>
					</template>
				</el-table-column>
				<el-table-column prop="accountEndDay" label="账户到期日" min-width="180px">
					<template v-slot="scope">
						<el-date-picker
										v-model="scope.row.accountEndDay"
										type="date"
										format="yyyy-MM-dd"
										value-format="yyyy-MM-dd"
										:disabled="scope.row.isPush === 1 ? true : false"
										placeholder="选择日期">
						</el-date-picker>
					</template>
				</el-table-column>
				<el-table-column prop="smallValidation" label="小额验证状态" min-width="120px">
					<template v-slot="scope">
						<span v-if="scope.row.smallValidation == 1">通过</span>
						<span v-else-if="scope.row.smallValidation == 2">打款申请通过</span>
						<span v-else>未通过</span>
					</template>
				</el-table-column>
				<el-table-column prop="taskStatusName" label="任务状态"></el-table-column>
				<el-table-column label="操作" fixed="right" min-width="180px">
					<template v-slot="scope">
						<el-button type="primary"
						           size="mini"
						           v-if="scope.row.smallValidation == '0'"
						           @click="handleSmallAmount(scope.row)">
							小额验证
						</el-button>
						<el-button type="delete" size="mini" @click="handleDel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--银行账户资料-->
			<div class="bank-info-box">
				<div class="file-title">
					<h3>银行账户资料</h3>
				</div>
				<div class="upload-explain">
					<p>
						<img src="~@/assets/img/ticket/explain.png" alt="">上传说明：加盖公章后再上传
					</p>
					<span>
					基本账户：开户许可证复印件<br/>
					一般账户：银行流水回单，网银截屏或者其他显示账户信息的复印件<br/>
          <!--个人账户：银行卡号正反面复印件或者存折账号页复印件，复印件上请写上开户姓名及开户网点-->
				</span>
				</div>
				<ul class="bank-info">
					<li v-for="item in info" :key="item.id">
						<p>账号：{{item.bankNumber}}</p>
						<div class="upload-box">
							<div class="upload-thumbnail" v-if="item.bankAccountData">
								<img class="upload-img" :src="item.bankAccountData" alt="">
							</div>
							<el-upload
											action="http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss"
											accept=".jpg,jpeg,.png"
											class="upload-demo"
											list-type="picture-card"
											name="multipartFile"
											:show-file-list="false"
											:on-success="(response, file, fileList)=> item.bankAccountData = response.data"
											v-if="item.isPush != 0">
								<i class="el-icon-plus"></i>
							</el-upload>
						</div>
					</li>
				</ul>
			</div>
		</el-card>
		<!--按钮-->
		<div class="btn mt30">
			<el-button type="primary" @click="handleSubmit">确认提交</el-button>
			<el-button @click="$router.go(-1)">上一步</el-button>
		</div>
		<!--更新成功后提示的弹框-->
		<el-dialog :visible.sync="successDialogVisible"
		           width="40%"
		           :show-close="false"
		           :close-on-click-modal="false"
		           @close="$router.go(-1)">
			<div class="agree-dialog">
				<img src="~@/assets/img/ticket/cg.png" alt="">
				<h3>{{copyText}}</h3>
				<div class="copy-code">
					<h4>{{copyCode}}</h4>
				</div>
				<el-button type="primary" @click="handleCopy">点击复制</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
		<!--小额验证弹框-->
		<el-dialog
						title="小额打款验证"
						:visible.sync="verifyDialogVisible"
						:show-close="false"
						width="50%"
						class="small-dialog"
						@close="handleVerifyClose">
			<el-alert title="业务说明：验证金额3次错误后，失效。用户需要重新验证"
			          type="warning"
			          :closable="false">
			</el-alert>
			<p style="margin-top: 10px; padding-left: 55px;">我们已向您尾号（{{bankNumber.substring(bankNumber.length - 4, bankNumber.length)}}）的银行账号转入验证小款，请尽快查收验证！</p>
			<el-form :model="verifyForm"
			         :rules="verifyFormRules"
			         ref="verifyFormRef"
			         label-width="140px"
			         class="mt20">
				<el-form-item label="验证金额（元）" prop="verifyAmount">
					<el-input type="number" v-model="verifyForm.verifyAmount" class="verify-code"></el-input>
				</el-form-item>
			</el-form>
			<div class="verify-result">{{verifyMsg}}</div>
			<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="handleSmallAmountAgain">重新验证</el-button>
					<el-button type="primary" @click="handleVerifySubmit">确 定</el-button>
			    <el-button @click="verifyDialogVisible = false">取 消</el-button>
			  </span>
		</el-dialog>
	</div>
</template>

<script>
  /*温馨提示*/
  import WarmPrompt from '@/views/Ticket/childrenCpn/WarmPrompt/Index'
  export default {
    name: "UpdateAccount",
    components: {
      WarmPrompt
    },
	  mounted(){
      // 获取更新数据
      this.getAccountInfo()
      // 获取账户类别数据
      this.getAccountType()
			// 获取cfca银行编码和名称
			this.getCfcaBank()
	  },
	  data(){
      return {
				cfcaBankList: [], //cfca银行
        flag: true, // 安全阀
        info: [], //获取到的数据
        AccountType: [], // 数据字典 - 账户类别
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        successDialogVisible: false, // 控制同意弹框的显示与隐藏
        bankExtId: 1, // 银行账户EXT表ID - 用于验证点击小额按钮是否弹框
        cfcaBankId: 0, // 后加的
        bankNumber: '', // 账号 - 用于显示在小额验证弹框
        verifyDialogVisible: false, // 控制小额验证弹框的显示与隐藏
        verifyMsg: '', //小额验证失败文字提示
        verifyForm: {
          verifyAmount: '', // 小额验证金额
        },
        verifyFormRules: {
          verifyAmount: [
            { required: true, message: '请输入验证金额', trigger: 'blur' }
          ]
        },
      }
	  },
	  methods: {
      // 获取更新数据
      async getAccountInfo(){
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/front-bank-ext/getBankInfoByCompanyId")
        if(res.code !== 200) return this.$message.error(res.msg);
        this.info = res.data
      },
      // 获取账户类别数据
      async getAccountType(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/sys-dict/getByType',{params: {type: 3}});
        if (res.code !== 200) return this.$message.error(res.msg)
        this.AccountType = res.data
      },
      // 监听是否结算账户
      /*isCloseAccountChange(value){
        this.flag = true;
        if (value.isCloseAccount == 1){
          this.info.forEach(item => {
            item.isCloseAccount = 0
            if (value.id == item.id){
              item.isCloseAccount = 1
            }
          })
        }
      },*/
      // 监听小额验证按钮
      async handleSmallAmount(row){
        this.bankExtId = row.bankExtId;
        this.cfcaBankId = row.cfcaBankId;
        this.bankNumber = row.bankNumber
        this.verifyMsg = ''; // 清空小额失败文字提示
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/front-bank-ext/paymentApply",{params: {bankExtId: this.bankExtId, cfcaBankId: row.cfcaBankId}});
        if (res.code === 200){
          this.verifyDialogVisible = true
        }else {
          this.$message.error(res.msg);
          // 重新获取数据
          this.getCompanyAccess()
        }
      },
      // 小额重新验证
      async handleSmallAmountAgain(){
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/front-bank-ext/paymentApply",{params: {bankExtId: this.bankExtId, cfcaBankId: this.cfcaBankId}});
        if (res.code === 200){
          this.verifyDialogVisible = true
          this.$message.success(res.data)
        }else {
          this.$message.error(res.msg);
          // 重新获取数据
          this.getCompanyAccess()
        }
      },
      // 小额弹框关闭
      handleVerifyClose(){
        this.$refs.verifyFormRef.resetFields()
      },
      // 小额验证确定
      handleVerifySubmit(){
        this.$refs.verifyFormRef.validate(async valid => {
          if(!valid) return;
          let params = {
            bankExtId: this.bankExtId,
            verifyAmount: this.verifyForm.verifyAmount
          }
          const {data: res} = await this.$auth.get("/cpiaoju-ticket/front-bank-ext/smallPaymentVerify",{params})
          if(res.code !== 200) {
            this.verifyMsg = res.msg
          }else {
            this.verifyDialogVisible = false
          }
        })
      },
		  //监听删除按钮
      handleDel(row){
        this.info = this.info.filter(item => row.id !== item.id)
      },
			// 获取cfca银行编码和名称
			async getCfcaBank(queryString = ''){
				const {data: res} = await this.$auth.get("/cpiaoju-ticket/cfca-bank-code/getCfcaBank", {params: {cfcaBankName: queryString}})
				if(res.code !== 200) return this.$message.error(res.msg);
				this.cfcaBankList = res.data.records;
			},
			// cfca模糊搜索
			querySearchAsync(queryString, cb){
				// 获取cfca银行编码和名称
				this.getCfcaBank(queryString)
				let result = queryString ? this.cfcaBankList.filter(this.createStateFilter(queryString)) : this.cfcaBankList
				cb(result)
			},
			createStateFilter(queryString) {
				return (state) => {
					return (state.bankName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			// 监听cfca模糊搜索选择
			handleSelect(row){
				let cfcaBankCodeName = row.cfcaBankCodeName
				let cfcaItem = this.cfcaBankList.filter(item => item.bankName == cfcaBankCodeName)
				row.cfcaBankId = cfcaItem[0].id
			},
      // 确认提交
      handleSubmit(){
        this.info.forEach(item => {
          try {
            //isCloseAccount 是否结算账户
            if (item.isCloseAccount == null){
              this.flag = false;
              return this.$message.error('是否结算账户不能为空')
            } else if (item.accountType == 30){ // accountType:账户类别 == 临时存款账户（30）
              if (item.accountStartDay == null || item.accountEndDay == null){// 判断当账户类别=临时存款账户，账户生效日、账户有效期必填
                this.flag = false
                return this.$message.error('账户日期不能为空')
              }else{
                this.flag = true
              }
            }else{
              // 判断是否结算账户=是，账户类别必填；
              if (item.isCloseAccount == 1 && !item.accountType){
                this.flag = false
                return this.$message.error('账户类别不能为空');
              }
            }
          }catch (e) {
            console.log(e)
          }
        })
        this.$nextTick(async () => {
          if (this.flag === true){
            let params = {
              bankExtListStr: JSON.stringify(this.info)
            }
            const {data: res} = await this.$auth.post('/cpiaoju-ticket/front-bank-ext/updBankInfoById',this.$qs.stringify(params))
            if(res.code !== 200) return this.$message.error(res.msg);
            this.copyCode = res.data.operationCode;
	          this.copyText = res.data.okMsg;
            this.successDialogVisible = true
          }
        })
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
	  }
  }
</script>

<style lang="less" scoped>
	/*银行账户资料*/
	.bank-info-box{
		.look-example{
			margin-left: 15px;
		}
		.upload-explain{
			background: #FFFBE6;
			width: 100%;
			line-height: 1.8;
			padding: 10px 15px;
			margin-top: 20px;
			border: 1px solid #FFFBE6;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			span{
				display: flex;
				line-height: 1.8;
				color: #666;
				padding-left: 20px;
				margin-top: 10px;
			}
			img{
				vertical-align: -2px;
				margin-right: 5px;
			}
			a{
				color: #C70009;
			}
		}
		.bank-info{
			li{
				margin-top: 20px;
				.upload-box{
					display: flex;
					margin-top: 10px;
					margin-left: 30px;
					.upload-demo{
						margin-left: 20px;
					}
					.upload-thumbnail{
						width: 148px;
						height: 148px;
						height: 148px;
						border-radius: 6px;
						border: 1px dashed #c0ccda;
						box-sizing: border-box;
						img{
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}
	/*账户信息*/
	.bankAccountList{
		.el-input{
			width: 150px !important;
		}
	}
	.btn{
		text-align: center;
	}
	/*同意收票弹框*/
	/deep/ .agree-dialog{
		display: flex;
		flex-direction: column;
		align-items: center;
		h3{
			margin-top: 20px;
		}
		.copy-code{
			margin-top: 15px;
			h4{
				display: inline-block;
				color: #C70009;
			}
		}
		.el-button{
			margin: 30px 24px 0;
		}
	}
	.small-dialog{
		.verify-code{
			width: 220px !important;
		}
		.verify-explain, .verify-result{
			color: #C70009;
		}
		.verify-explain{
			margin-top: 20px;
		}
		.verify-result{
			margin-left: 35px;
		}
	}
</style>